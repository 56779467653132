/* CSS RESET
---------------------------------------------- */
* {
  box-sizing: border-box;
}
html, body {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	vertical-align: baseline;
  word-break: break-word;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
td {
  vertical-align: top;
}
h1, h2, h3, h4, h5 {
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-weight: normal;
  font-size: inherit
}
a {
  text-decoration: none;
  color: inherit;
  outline: none;
}
a:hover {
  outline: none;
}
a:focus,
button:focus {
  outline: none;
}
a:focus-visible{
  outline: 2px solid black;
  outline-offset: 2px;
}
a:focus-visible {
  background: none;
}
p {
  margin-block-start: 0rem;
  margin-block-end: 0rem;
  margin-bottom: 1em;
}
p:last-of-type {
  margin-bottom: 0rem;
}
figure {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
button {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  padding: 0;
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  text-transform: inherit;
}
/* button:active {
  outline: none;
  border: none;
}
button:focus {
  outline: 0;
} */
button:hover {
  cursor: pointer;
}
ol li:not(:last-of-type) {
  margin-bottom: 0rem;
}
ol.unstyled-list, ul.unstyled-list, ul, .unstyled-list ul{
  display: block;
  list-style-type: none;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
li.unstyled-list-item {
  display: block;
  text-align: -webkit-match-parent;
}
dd {
  margin-inline-start: 0px;
}
*:focus{
  outline: none;
}
input {
  border-radius: 0;
}
textarea, input[type=text], input[type=button], input[type="submit"], input[type=email], input[type=tel] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  -moz--border-radius: 0;
  border-radius: 0;
  font-weight: inherit;
  font-family: inherit;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  border: none;
  width: 100%;
  padding: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-block-start: 0em;
  padding-inline-start: 0em;
  padding-inline-end: 0em;
  padding-block-end: 0em;
  min-inline-size: min-content;
}
blockquote {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}