@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Inter-Regular.woff2") format("woff2"),
       url("../fonts/Inter-Regular.woff") format("woff");
}

@font-face {
  font-family: 'Saol';
  font-style:  normal;
  font-weight: 200;
  font-display: swap;
  src: url("../fonts/SaolDisplay-Light.otf") format("opentype");
}

@font-face {
  font-family: 'Saol';
  font-style:  italic;
  font-weight: 200;
  font-display: swap;
  src: url("../fonts/SaolDisplay-LightItalic.woff2") format("woff2"),
       url("../fonts/SaolDisplay-LightItalic.woff") format("woff");
}