  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b *,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b *::before,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b *::after {
    box-sizing: border-box;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b [tabindex="-1"]:focus {
    outline: none !important;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b h1,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b h2,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b h3,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b h4,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b h5,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b h6 {
    margin-top: 0;
    margin-bottom: 0.7em;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b ol,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b ul,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b dl {
    margin-top: 0;
    margin-bottom: 1.4rem;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b ol ol,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b ul ul,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b ol ul,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b ul ol {
    margin-bottom: 0;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b strong {
    font-weight: bolder;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b small {
    font-size: 80%;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b sub,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b sub {
    bottom: -0.25em;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b sup {
    top: -0.5em;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b {
    color: $black;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b a:hover {
    color: #4396fd;
    text-decoration: none;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b img {
    border-style: none;
    vertical-align: middle;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b svg:not(:root) {
    overflow: hidden;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b area,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b button,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b [role="button"],
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b input,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b label,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b select,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b summary,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b textarea {
    touch-action: manipulation;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b label {
    display: inline-block;
    font-weight: bolder;
    margin-bottom: 0.7rem;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b button:focus {
    outline: 1px dotted;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b input,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b button,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b select,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b optgroup,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b textarea {
    margin: 0;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b button,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b input {
    overflow: visible;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b button,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b select {
    text-transform: none;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b button,
  html[data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b [type="button"],
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b [type="reset"],
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b [type="submit"] {
    -webkit-appearance: button;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b button::-moz-focus-inner,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b [type="button"]::-moz-focus-inner,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b [type="reset"]::-moz-focus-inner,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b input[type="radio"],
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b input[type="checkbox"] {
    padding: 0;
    box-sizing: border-box;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b input[type="date"],
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b input[type="time"],
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b input[type="datetime-local"],
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b input[type="month"] {
    -webkit-appearance: listbox;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b textarea {
    resize: vertical;
    overflow: auto;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b [type="number"]::-webkit-inner-spin-button,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b [type="search"]::-webkit-search-cancel-button,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b [hidden] {
    display: none !important;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-control {
    width: 100%;
    display: block;
    outline: none;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-control:focus {
    outline: none;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-control::-webkit-input-placeholder {
    color: transparent !important;
    opacity: 0 !important;
    text-transform: uppercase!important;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-control::-moz-placeholder {
    color: transparent !important;
    opacity: 0 !important;
    text-transform: uppercase!important;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-control::placeholder {
    color: transparent !important;
    opacity: 0 !important;
    text-transform: uppercase!important;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-label {
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-control:not(:-moz-placeholder-shown)+.fd-form-label {
    opacity: 0;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-control:not(:placeholder-shown)+.fd-form-label {
    opacity: 0;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-description {
    margin: 5px 0 0 0;
    font-size: 0.8em;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-feedback {
    margin: 5px 0 0 0;
    font-size: 0.8em;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-group {
    margin: 0 0 15px;
    position: relative;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-group.fd-has-success .fd-form-feedback,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-group.fd-has-success .fd-form-check {
    color: #02dba8 !important;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-group.fd-has-success .fd-form-control {
    color: #02dba8 !important;
    border-color: #02dba8 !important;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-group.fd-has-success .fd-form-feedback {
    display: block;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-group.fd-has-error .fd-form-feedback,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-group.fd-has-error .fd-form-check {
    color: #eb3d3b !important;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-group.fd-has-error .fd-form-control {
    color: #eb3d3b !important;
    border-color: #eb3d3b !important;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-group.fd-has-error .fd-form-feedback {
    display: block;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-btn {
    cursor: pointer;
    display: -webkit-inline-box;
    display: inline-flex;
    outline: none;
    max-width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-style: normal;
    text-align: center;
    -webkit-box-align: center;
    align-items: center;
    text-shadow: none;
    white-space: normal;
    -webkit-box-pack: center;
    justify-content: center;
    text-decoration: none;
    transition: .4s;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-btn:hover {
    outline: none;
    background: white;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-btn:focus {
    outline: none;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-btn:disabled {
    opacity: 0.8;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-check {
    cursor: pointer;
    margin: 0;
    display: -webkit-box;
    display: flex;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    padding-left: 30px;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-check__input {
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    opacity: 0;
    z-index: -1;
    position: absolute;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-check__checkmark {
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    display: block;
    position: absolute;
    background-size: 18px;
    background-image: url("data:image/svg+xml,%3csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M1 4C1 2.34315 2.34315 1 4 1H16C17.6569 1 19 2.34315 19 4V16C19 17.6569 17.6569 19 16 19H4C2.34315 19 1 17.6569 1 16V4Z' fill='white'/%3e %3cpath fill='black' fill-rule='evenodd' clip-rule='evenodd' d='M0.25 4C0.25 1.92893 1.92893 0.25 4 0.25H16C18.0711 0.25 19.75 1.92893 19.75 4V16C19.75 18.0711 18.0711 19.75 16 19.75H4C1.92893 19.75 0.25 18.0711 0.25 16V4ZM4 1.75C2.75736 1.75 1.75 2.75736 1.75 4V16C1.75 17.2426 2.75736 18.25 4 18.25H16C17.2426 18.25 18.25 17.2426 18.25 16V4C18.25 2.75736 17.2426 1.75 16 1.75H4Z'/%3e %3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center center;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-check__label {
    margin: 0;
    font-size: 14px;
    text-align: left;
    word-break: break-word;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.01em;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-check__input:checked+.fd-form-check__checkmark::after {
    opacity: 1;
    z-index: 1;
    visibility: visible;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-check__checkmark::after {
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    content: "";
    display: block;
    opacity: 0;
    z-index: 1;
    position: absolute;
    -webkit-transition: opacity 0.4s, z-index 0.4s;
    transition: opacity 0.4s, z-index 0.4s;
    visibility: inherit;
    background-size: 12px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='black' width='12' height='10' viewBox='0 0 11.51 8.2'%3e%3ctitle%3echeck%3c/title%3e%3cpath d='M4.05%2c8.2A.74.74%2c0%2c0%2c1%2c3.52%2c8L.22%2c4.68A.75.75%2c0%2c0%2c1%2c1.28%2c3.62l3.3%2c3.3A.75.75%2c0%2c0%2c1%2c4.58%2c8%2c.74.74%2c0%2c0%2c1%2c4.05%2c8.2Z'/%3e%3cpath d='M4.06%2c8.2A.74.74%2c0%2c0%2c1%2c3.53%2c8a.75.75%2c0%2c0%2c1%2c0-1.06l6.7-6.7a.75.75%2c0%2c0%2c1%2c1.06%2c1.06L4.59%2c8A.74.74%2c0%2c0%2c1%2c4.06%2c8.2Z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center center;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-check__input:focus {
    outline: none;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-content {
    position: relative;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-has-success .fd-form-content {
    display: none;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-has-captcha .fd-form-content>*:not(.fd-form-captcha) {
    opacity: 0;
    visibility: hidden;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-captcha {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: flex;
    position: absolute;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-success {
    display: none;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-has-success .fd-form-success {
    display: block;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-success>*:last-child {
    margin-bottom: 0;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-error {
    display: none;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-has-error .fd-form-error {
    display: block;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-error>*:last-child {
    margin-bottom: 0;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-focus-visible,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-check__input.fd-focus-visible+.fd-form-check__checkmark {
    outline: none;
    box-shadow: 0 0 0 2px #fff, 0 0 0 6px #000 !important;
    -webkit-transition: box-shadow 0.2s !important;
    transition: box-shadow 0.2s !important;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-focus-visible,
  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .fd-form-check__input.fd-focus-visible+.fd-form-check__checkmark {
    outline: none;
    box-shadow: 0 0 0 2px transparent, 0 0 0 6px #000 !important;
    -webkit-transition: box-shadow 0.2s !important;
    transition: box-shadow 0.2s !important;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b {
    background: transparent;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__container {
    margin: 0 auto;
    max-width: 1170px;
    background: transparent;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__form {
    color: $black;
    width: 100%;
    margin: 0;
    padding-top: 30px;
    font-size: 16px;
    font-family: $body-font;
    font-weight: 300;
    line-height: 1.6;
    letter-spacing: 0.1px;
    text-transform: none;
  }

  @media (max-width: 767px) {
    [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__form {
      word-wrap: anywhere;
      word-break: break-word;
      white-space: normal;
      overflow-wrap: break-word;
    }
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__content {
    margin: -10px -5px 0;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: start;
    align-items: start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }

  @media (max-width: 767px) {
    [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__content {
      display: block;
    }
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__fields {
    -webkit-box-flex: 0;
    flex: 0 1 auto;
    margin: 0px 0px 0;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    max-width: calc(100% + 10px);
    -webkit-box-pack: center;
    justify-content: center;
  }

  @media (max-width: 767px) {
    [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__fields {
      display: block;
    }
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__field {
    -webkit-box-flex: 1;
    flex: 1 1;
    margin: 10px 5px 0 0;
    font-size: 13px;
    max-width: 250px;
    min-width: 140px;
    text-align: left;
    font-family: $body-font;
    font-weight: 400;
    letter-spacing: 0.1px;
    text-transform: uppercase;
  }

  @media (max-width: 767px) {
    [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__field {
      max-width: 100%;
      margin-right: 0px;
    }
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__control {
    color: $black;
    border: 1px solid $black;
    height: 46px;
    padding: 12px 20px;
    font-size: 13px;
    background: transparent;
    text-align: left;
    font-family: $body-font;
    font-weight: 400;
    line-height: 20px;
    border-radius: 0px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__label {
    color: $black;
    border: 1px solid transparent;
    padding: 12px 20px;
    font-size: 13px;
    text-align: left;
    font-family: $body-font;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__preference {
    margin: 30px 5px 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__preference-title {
    color: #333333;
    width: 100%;
    margin: 0 0 30px;
    display: block;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0px;
    text-transform: none;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__preference-title * {
    line-height: inherit;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__preference-control {
    width: 100%;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__preference-list {
    width: calc(100% + 24px);
    margin: -8px -12px;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }

  @media (max-width: 767px) {
    [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__preference-list {
      display: block;
    }
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__preference-item {
    -webkit-box-flex: 0;
    flex: 0 0 33.333333333333336%;
    padding: 8px 12px;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__form-check .fd-form-check__input {
    top: 2.200000000000001px;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__form-check .fd-form-check__checkmark {
    top: 2.200000000000001px;
    background-image: url("data:image/svg+xml,%3csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M1 4C1 2.34315 2.34315 1 4 1H16C17.6569 1 19 2.34315 19 4V16C19 17.6569 17.6569 19 16 19H4C2.34315 19 1 17.6569 1 16V4Z' fill='white'/%3e %3cpath fill='black' fill-rule='evenodd' clip-rule='evenodd' d='M0.25 4C0.25 1.92893 1.92893 0.25 4 0.25H16C18.0711 0.25 19.75 1.92893 19.75 4V16C19.75 18.0711 18.0711 19.75 16 19.75H4C1.92893 19.75 0.25 18.0711 0.25 16V4ZM4 1.75C2.75736 1.75 1.75 2.75736 1.75 4V16C1.75 17.2426 2.75736 18.25 4 18.25H16C17.2426 18.25 18.25 17.2426 18.25 16V4C18.25 2.75736 17.2426 1.75 16 1.75H4Z'/%3e %3c/svg%3e");
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__form-check .fd-form-check__label {
    color: #333333;
    font-size: 14px;
    min-height: 22.400000000000002px;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: 0px;
    text-transform: none;
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__form-check .fd-form-check__checkmark::after {
    background-size: 12px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='black' width='12' height='10' viewBox='0 0 11.51 8.2'%3e%3ctitle%3echeck%3c/title%3e%3cpath d='M4.05%2c8.2A.74.74%2c0%2c0%2c1%2c3.52%2c8L.22%2c4.68A.75.75%2c0%2c0%2c1%2c1.28%2c3.62l3.3%2c3.3A.75.75%2c0%2c0%2c1%2c4.58%2c8%2c.74.74%2c0%2c0%2c1%2c4.05%2c8.2Z'/%3e%3cpath d='M4.06%2c8.2A.74.74%2c0%2c0%2c1%2c3.53%2c8a.75.75%2c0%2c0%2c1%2c0-1.06l6.7-6.7a.75.75%2c0%2c0%2c1%2c1.06%2c1.06L4.59%2c8A.74.74%2c0%2c0%2c1%2c4.06%2c8.2Z'/%3e%3c/svg%3e");
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__footer {
    -webkit-box-flex: 0;
    flex: 0 1 auto;
    margin-top: 10px;
    text-align: center;
    margin-right: 5px;
  }

  @media (max-width: 767px) {
    [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__footer {
      margin-top: 5px;
      margin-right: 0px;
    }
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__button {
    color: $black;
    width: auto;
    border: 1px solid $black;
    display: inline-block;
    padding: 12px 20px;
    font-size: 13px;
    background: transparent;
    text-align: center;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
    border-radius: 0px;
    letter-spacing: 0.1px;
    text-transform: none;
    text-transform: uppercase;
  }

  @media (max-width: 767px) {
    [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__button {
      width: 100%;
    }
  }

  [data-ff-el="root"].ff-64d1f3cb59cf153a752d1e2b .ff-64d1f3cb59cf153a752d1e2b__error {
    margin: 10px 0 0 0;
  }