
@mixin media {
  position: relative;
  figure {
    line-height: 0
  }
}

@mixin object-fit-video {
  figure {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: center;
    overflow: hidden;
    margin: 0 auto;
  }
  video {
    display: block;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
}

@mixin split-media {
  height: 100%;
  img {
    object-fit: cover;
    height: 100%;
  }
  @include object-fit-video;
}

@mixin split-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @include for-small-tablet-below {
    grid-template-columns: 1fr;
  }
}

@mixin square-button {
  a {
    padding: 1.5rem 5rem;
    border: 1px solid $black;
    display: inline-block;
    transition: .4s;
  }
}